export const environment = {
  production: false,
  api_url: 'https://staging.payplus-comviva.com/invoice-payments',
  api_auth_url: 'https://staging.payplus-comviva.com/authorization',
  smart_routing: 'https://staging.payplus-comviva.com/smart-routing/smart-routing',
  caching_api_url: 'https://staging.payplus-comviva.com/caching',
  api_mpg_url: 'https://staging.payplus-comviva.com/ecom',
  api_mpg_wallet_url: 'https://staging.payplus-comviva.com',
  ecomtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzc3NzIzNTQ5LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiI5MWZmYmVmMC1lYjQ5LTRiYTItOGFmYy01NzhmNDg3NmExZjgiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.ElU_Tt7230iGm7yPIXEHrXPbupo_8lsHvqZnyO4LGsfQzOpV_O7CVGGOoeGfbE6lQpUUbQdn3gvaPm3CVywNxb01QUuiA61uuVelGGK0H2eEky-JMT5zEop_IvuT_YrYh2wr2nHA43R0IMddFpCPAMCkKeVSmYjqVSgYQ1o8hKjXHjBIpau93Se6F7hvijfjuVHqlIK5dAnqp1laASpK_WubTls21fHXhkSz_ih323wvgLxnwmsO-Z5IY7m2ydiXJu2aKcdainLUgRIkyH1aH3eoYMlouIO9c5jeEHX_iRxaizmSYi5O0bk-94XIf3gHjyFy3xAmm8r2lhcrDGAYWQ',
  invtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzc3NzIzNTQ5LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiI5MWZmYmVmMC1lYjQ5LTRiYTItOGFmYy01NzhmNDg3NmExZjgiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.ElU_Tt7230iGm7yPIXEHrXPbupo_8lsHvqZnyO4LGsfQzOpV_O7CVGGOoeGfbE6lQpUUbQdn3gvaPm3CVywNxb01QUuiA61uuVelGGK0H2eEky-JMT5zEop_IvuT_YrYh2wr2nHA43R0IMddFpCPAMCkKeVSmYjqVSgYQ1o8hKjXHjBIpau93Se6F7hvijfjuVHqlIK5dAnqp1laASpK_WubTls21fHXhkSz_ih323wvgLxnwmsO-Z5IY7m2ydiXJu2aKcdainLUgRIkyH1aH3eoYMlouIO9c5jeEHX_iRxaizmSYi5O0bk-94XIf3gHjyFy3xAmm8r2lhcrDGAYWQ',
  loyalty_url: 'https://staging.payplus-comviva.com/loyalty/loyalty',  
  service_integrator_url: 'https://staging.payplus-comviva.com/service-integrator/ccavenue',
  redirectHtmlFlag: 'TRUE',
  retryCount: '2',
  successTimeout: '2',
  failureTimeout: '5'
};
