import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PaymentRolesService, ISimpleDelinkPayload, ISimplPayload, ISimpleEligibility, CnpdataService, ILightBoxData, HeaderService, PageTypes, UserdataService, IAdditionalDetails, ITxnData, SimplScreen } from '../core';

@Component({
  selector: 'app-pay-later',
  templateUrl: './pay-later.component.html',
  styleUrls: ['./pay-later.component.scss']
})
export class PayLaterComponent implements OnInit {
  @Output()
  showHome: EventEmitter<boolean> = new EventEmitter<boolean>();
  payLaters: any = {};
  initiateContextPath = '';
  currentPayLater = 0;
  payLaterToDisplay: Array<any> = [];
  selectedOption: string;
  selectedValue = { payLater: '' };
  selectedImage = '';
  confirmContextPath = '';
  paymentFlowType = '';
  paymentInstrumentType: any;
  lightBoxData: ILightBoxData;
  lightboxEnable: boolean;
  PageTypes = PageTypes;
  lboxAdditionalDetails: IAdditionalDetails;
  simplEligibleToken: string = '';
  isOptionSelected: boolean = false;
  isEligible: boolean = true;
  additionalDetails: IAdditionalDetails = {} as IAdditionalDetails;
  availableCredit: number;
  creditAvailable: boolean = false;
  linkEnabled: boolean = false;
  isDropdownOpen: boolean = false;
  token: string;
  redirectionUrl: string;
  insufficientCredit: boolean = false;
  pendingDues: boolean  = false;
  delinkAvailable: boolean = false;
  currentScreen = "";
  txnStatus = false;
  txnData: ITxnData = {} as ITxnData;
  transactionId = '';
  SimplScreen = SimplScreen;

  constructor(
    private paymentRole: PaymentRolesService,
    private cnpDataService: CnpdataService,
    public headerService: HeaderService,
    private userdataService: UserdataService,
  ) { }

  ngOnInit() {
    this.fetchRole();
    this.lightboxEnable = this.headerService.getPageType() === PageTypes.LB;
    if (this.lightboxEnable) {
      this.initLbox();
    }
    this.checkEligibility();
  }

  initLbox(): void {
    this.lightBoxData = this.userdataService.getLightboxInfo();
    this.lboxAdditionalDetails = this.userdataService.getAdditionalData();
    this.additionalDetails = this.userdataService.getAdditionalData();
  }

  gotoHome(clear?: boolean): void {
    this.showHome.emit(clear);
  }

  fetchRole() {
    const roles = this.paymentRole.getRoles();
    Object.keys(roles).forEach(role => {
      if (roles[role].paymentInstrumentType === "PayLater") {
        this.payLaters[role] = roles[role];
        this.payLaters[role].role = role;
        this.initiateContextPath = roles[role].initiatePaymentContextPath;
        this.paymentInstrumentType = roles[role].paymentInstrumentType
      }
    });
    this.doNext();
  }

  doNext() {
    const payLaterNames = Object.keys(this.payLaters).sort();
    for (this.currentPayLater = 0; this.currentPayLater < payLaterNames.length; this.currentPayLater++) {
      this.payLaterToDisplay.push(this.payLaters[payLaterNames[this.currentPayLater]]);
    }
  }

  setradio(payLater: string): void {
    this.selectedOption = payLater;
    this.selectedValue.payLater = payLater;
    this.selectedImage = this.getPayLaterLogoPath(this.payLaters[payLater].logo);
    this.initiateContextPath = this.payLaters[payLater].initiatePaymentContextPath;
    this.confirmContextPath = this.payLaters[payLater].confirmPaymentContextPath;
    this.paymentFlowType = this.payLaters[payLater].paymentFlowType;
    this.paymentInstrumentType = this.payLaters[payLater].paymentInstrumentType;
    this.isOptionSelected = true;
  }

  getPayLaterLogoPath(logoUrl: string): string {
    const url = new URL(logoUrl);
    const pathname = url.pathname;
    const filenameMatch = pathname.match(/\/([^\/?]+)$/);
    const filename = filenameMatch ? filenameMatch[1] : null;
    const cleanedFilename = filename ? filename.replace(/^paymentInstrument_/i, '').replace(/\.action$/i, '') : null;
    const logoPath = cleanedFilename ? `assets/images/${cleanedFilename}.png` : null;
    return logoPath;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  checkEligibility() {
    this.delinkAvailable = false;
    this.creditAvailable = false;
    let checkEligibilityPayLoad: ISimpleEligibility = {
      paymentChannelId: this.lightBoxData.paymentChannelId,
      applicationLabel: "Simpl",
      method: this.paymentInstrumentType,
      hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
      consumerMsisdn: "8095799666",
      hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
      amount: "1",
      merchantRedirectURL: `https://uat-r2.fnp.com/checkout/order-confirmation?orderId=${this.lightBoxData.orderId}`,
      orderId: this.lightBoxData.orderId
    }
    this.cnpDataService.checkEligibilityApiSimpl(checkEligibilityPayLoad).subscribe(resp => {
      if (resp.responseCode == "4460") {
        this.linkEnabled = true;
        this.isEligible = true;
        this.redirectionUrl = resp.redirectionUrl;
      }
      else if (resp.responseCode == "4462") {
        this.insufficientCredit = true;
        this.isEligible = true;
        this.redirectionUrl = resp.redirectionUrl;
      }
      else if (resp.responseCode == "4463") {
        this.pendingDues = true;
        this.isEligible = true;
        this.redirectionUrl = resp.redirectionUrl;
      }
      else if (resp.status == "eligible" && resp.linkingEnabled == null && resp.availableCredit != 0) {
        this.creditAvailable = true;
        this.availableCredit = resp.availableCredit;
        this.token = resp.token;
        this.delinkAvailable = true
      }
    })
  }

  proceedToPay() {
    if (this.linkEnabled == true) {
      window.open(this.redirectionUrl);
      return;
    }
    const { customerFirstName = "", customerLastName = "" } = this.additionalDetails || {};
    let simplPayload: ISimplPayload = {
      paymentChannelId: this.lightBoxData.paymentChannelId,
      hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
      payLaterToken: this.token,
      consumerMsisdn: "6360412664",
      hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
      amount: this.lightBoxData.transactionAmount,
      taxAmount: '1',
      currencyCode: this.lightBoxData.currency,
      timezone: "India Standard Time",
      emailAddress: this.lightBoxData.email,
      applicationLabel: this.selectedOption,
      customerCode: this.lightBoxData.customerCode,
      orderId: this.lightBoxData.orderId,
      checkSumPayment: this.lightBoxData.checkSumPayment,
      electronicGoodsIndicator: 'D',
      additionalDetails: JSON.stringify(this.additionalDetails),
      customerFirstName,
      customerLastName,
      method: this.paymentInstrumentType,
      merchantRedirectURL: "https://uat-r2.fnp.com/checkout/order-confirmation?orderId=48959500"
    }
    this.cnpDataService.simplInitiatePayment(simplPayload).subscribe(resp => {
      const paymentResponse = resp.initiatePaymentResponses[0];
      this.transactionId = paymentResponse.transactionId;
      if (resp.initiatePaymentResponses && resp.initiatePaymentResponses.length > 0) {
        const dueBy = resp.initiatePaymentResponses[0].dueBy;
        if (dueBy) {
          this.txnData.transactionId = this.transactionId;
          this.txnStatus = true;
        }
        else {
          this.txnStatus = false;
        }
      }
      this.showTxnComplete();
    })
  }

  delink() {
    let simplDelinkPayload: ISimpleDelinkPayload = {
      applicationLabel: this.selectedOption,
      paymentChannelId: this.lightBoxData.paymentChannelId,
      hierLabelIdReference: this.lightBoxData.hierLabelIdReference,
      hierarchyCriteria: this.lightBoxData.hierarchyCriteria,
      consumerMsisdn: "6360412664"
    }
    this.cnpDataService.delink(simplDelinkPayload).subscribe(resp => {
      if (resp.responseCode == "0000") {
        this.checkEligibility();
      }
    })
  }

  showTxnComplete(): void {
    this.headerService.changeHeaderStatus(false);
    this.currentScreen = this.SimplScreen.COMPLETE;
  }
}
